aside.ant-layout-sider.ant-layout-sider-dark.sidebar {
  background-color: $white !important;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  // width: 250px !important;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-zero-width.sidebar {
  flex: 0 0 0 !important;
  max-width: 0 !important;
  min-width: 0 !important;
  width: 0 !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #2b3344 !important;
  border-radius: 10px !important;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  padding: 10px !important;
}

.active {
  font-weight: bold;
}

/*Main Lyaout Start*/
#main-layout .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  color: white;
  cursor: pointer;
  transition: color 0.3s;
}

#main-layout .trigger:hover {
  color: $white;
}

#main-layout .logo {
  height: 32px;
  margin: 16px;
}

#main-layout .ant-layout {
  background: #f1f5f9 !important;
}

#main-layout .ant-layout-header {
  background: $primary !important;
}

#content-full {
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#overflow-menu {
  padding: 15px;
}

/*Main Lyaout End*/
/*Sidebar Lyaout start*/

// .site-layout>.content-area {
//   margin-left: 250px !important;
// }

.ant-menu-light .ant-menu-item-selected {
  background-color: $primary !important;
  color: $white !important;
}

.card-info {
  padding: 15px;
  border: none;

  p {
    margin: 0;
  }

  .ant-card-body {
    padding: 12px 0px !important;
    background: #f3f4f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-image {
    .profile-img {
      border: 2px solid $primary;
      border-radius: 50%;
    }
  }

  .edit-img {
    padding: 8px;
    cursor: pointer;
    background: $primary;
    border-radius: 5px;
  }
}

/*Sidebar Lyaout End*/

.fixed-select {
  .ant-select {
    outline: none !important;
    box-shadow: none !important;
    width: 120px !important;

    .ant-select-selector {
      text-align: left;
    }
  }
}

.delazfixed-select {
  .ant-select {
    outline: none !important;
    box-shadow: none !important;
    width: 200px !important;

    .ant-select-selector {
      text-align: left;
    }
  }
}
.ant-popconfirm-description {
  margin-top: 0 !important;
}

