.login-wrapper {
  .login-logo-wrapper {
    text-align: center;
  }
  .ant-card {
    border: 1px solid #443527;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    min-width: 400px;
  }
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  .bottom-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.business-wrapper {
  width: 40%;

  .ant-form-item-explain-error {
    margin: 5px 0;
  }

  .ant-upload-select {
    width: 85px !important;
    height: 85px !important;
  }

  .login-logo-wrapper {
    text-align: center;
  }

  .ant-card {
    border: 1px solid #443527;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  .bottom-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
  :where(
      .css-dev-only-do-not-override-1m99l7i
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
    border-color: #d9d9d9 !important;
  }
}

@include desktop {
  .business-wrapper {
    width: 65%;
  }
}
@include tablet {
  .business-wrapper {
    width: 100%;
    margin: 0 15px;
  }
}
