.main-card-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-radius: 10px;

  .ant-form-inline {
    display: flex;
    flex-wrap: nowrap !important;
  }

  .text-card {
    padding: 5px;
    color: $white;
    p {
      margin-left: 5px;
    }
  }

  .font-title {
    font-size: 15px;
  }

  .font-text {
    margin: 0;
    font-size: 25px;
  }

  .card-blue {
    border-radius: 10px;
    background: linear-gradient(180deg, #099bda 0%, #1089c3 100%);
  }

  .card-yellow {
    border-radius: 10px;
    background: linear-gradient(180deg, #f7a21c 0%, #ee7213 100%);
  }

  .card-pink {
    border-radius: 10px;
    background: linear-gradient(180deg, #e94894 0%, #ef4662 100%);
  }

  .card-light-blue {
    border-radius: 10px;
    background: linear-gradient(180deg, #51b3fa 0%, #467fed 100%);
  }

  .image-card-blue {
    background: #3aa9e0;
    padding: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-card-yellow {
    background: #ffad5f;
    padding: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-card-pink {
    background: #f46992;
    padding: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-card-light-blue {
    background: #4a90f1;
    padding: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// .ant-popconfirm {
// }

.text-card {
  padding: 5px;
  // padding-left: 15px;
  color: $white;

  p {
    margin-left: 5px;
  }
}

.font-title {
  font-size: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.font-text {
  margin: 0;
  // font-size: 25px;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
}

.card-blue {
  border-radius: 10px;
  background: linear-gradient(180deg, #099bda 0%, #1089c3 100%);
}

.card-yellow {
  border-radius: 10px;
  background: linear-gradient(180deg, #f7a21c 0%, #ee7213 100%);
}

.card-pink {
  border-radius: 10px;
  background: linear-gradient(180deg, #e94894 0%, #ef4662 100%);
}

.card-light-blue {
  border-radius: 10px;
  background: linear-gradient(180deg, #51b3fa 0%, #467fed 100%);
}

.image-card-blue {
  background: #3aa9e0;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-card-yellow {
  background: #ffad5f;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-card-pink {
  background: #f46992;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-card-light-blue {
  background: #4a90f1;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-property {
  .body-padding {
    .ant-card-body {
      padding: 0;
    }
  }
  .ant-descriptions-bordered {
    border: none !important;
    border-radius: 0;

    .ant-descriptions-view {
      border-radius: 0;
    }

    .ant-descriptions-item-label {
      background-color: transparent;
      font-weight: 700;
    }
  }
}
.text-justify {
  .ant-col-24 {
    text-align: justify;

    .ant-checkbox-wrapper {
      margin: 4px 0;
    }
  }
}

.main-button-text {
  .ant-btn-text {
    width: 20px !important;
    min-width: 20px !important;
    background-color: transparent !important;
  }

  :where(
      .css-dev-only-do-not-override-1me4733
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
  :where(
      .css-dev-only-do-not-override-1me4733
    ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
    border-color: #ff9a3e;
  }
}
.filter-modal {
  .m-0 {
    margin: 0;
  }
  .ant-modal-title {
    font-size: 20px;
  }
  .ant-modal-body {
    padding: 10px 0;

    .ant-input {
      // height: 50px;
    }
    .ant-form-large .ant-form-item .ant-form-item-label > label {
      font-size: 15px;
    }
    .ant-form-item-control-input {
      margin-bottom: 10px;
    }
    .mb-0 .ant-form-item-control-input {
      margin-bottom: 0 !important;
    }
    .ant-form-item-label {
      padding: 0;
    }
    .hidden-label {
      .ant-form-item-label {
        opacity: 0;
      }
    }
  }
}
