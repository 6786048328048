.ant-tabs .ant-tabs-tab {
    color: black;
    font-size: 20px;
    font-weight: 500;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FF9A3E;
  }
  .ant-tabs .ant-tabs-tab:hover {
    color: #FF9A3E;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: #FF9A3E;
  }