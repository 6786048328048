@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");

// Color variables
$white: #ffffff;

$primary: #FF9A3E;
$success: #58db83;
$info: #29bbe3;
$warning: #f5b225;
$danger: #ec536c;
$dark: #2a3142;
$muted: #707070;

// Brand Colors
$color-1: #f4e1c3;
$color-1-transparent: #17a5982e;
$color-2: #de4b31;
$color-3: #32a067;
$color-4: #121921;
$color-5: #ffc107;

// Gray Colors
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$bg-body: #f5f5f5;
$left-bar: #181f47;

$font-family: "Poppins", sans-serif;

$shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
$base-font: 13px;

// Width variables
$width: 100%;

// Height variables
$height: 100%;

$leftbar-menu-color: #8699ad;
$leftbar-menu-hover-color: #ffffff;
$leftbar-menu-active-color: #ffffff;
